import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import {
  TextInput,
  Form,
  required,
  useNotify,
  useRefresh,
  DeleteButton,
} from "react-admin";
import { doc, getFirestore, updateDoc } from "firebase/firestore"; 

const Content = ({tenantId, handleClose}) => {
  const [loading, setLoading] = React.useState(false)
  const notify = useNotify();
  const refresh = useRefresh();

  const updateContact = async (values) => {
    setLoading(true);
    try {
      const db = getFirestore();
      const ref = doc(db, "vendorContacts", values.id);
      await updateDoc(ref, {
        name: values.name,
        email: values.email,
        phone: values.phone,
      });

      notify(`Contact updated!`, { type: "success" });
      handleClose();
      refresh();
    } catch (error) {
      notify(`Error occured when updating contact.`, { type: "error" });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={updateContact}>
      <DialogContent>
        <TextInput
          label="Name"
          source="name"
          fullWidth
          validate={[required()]}
        />
        <TextInput
          label="Email"
          source="email"
          type="email"
          fullWidth
          required
        />
        <TextInput
          label="Phone"
          source="phone"
          fullWidth
          validate={[required()]}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <DeleteButton
            resource="vendorContacts"
            mutationMode="pessimistic"
            redirect={false}
          />
          <Box>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <LoadingButton type="submit" variant="outlined" loading={loading}>
              Save
            </LoadingButton>
          </Box>
        </Box>
      </DialogContent>
    </Form>
  );
}

export default function FormDialog({tenantId}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="text" onClick={handleClickOpen} startIcon={<EditIcon />}>
        Edit
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit Contact</DialogTitle>
        <Content tenantId={tenantId} handleClose={handleClose}/>
      </Dialog>
    </>
  );
}
