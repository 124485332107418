import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  useTheme,
  Divider
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import ClipboardIcon from "@mui/icons-material/ContentPaste";
import {
  Form,
  useNotify,
  useGetRecordId,
  AutocompleteInput,
  useGetList,
} from "react-admin";
import { httpsCallable, getFunctions } from "firebase/functions";

const CopyToClipboard = ({ checkoutLink }) => {
  const theme = useTheme();
  const notify = useNotify();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(checkoutLink);
    notify(`Link Copied!`, { type: "success" });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        border: "1px solid",
        borderRadius: "4px",
      }}
    >
      <Input
        value={checkoutLink}
        sx={{
          flexGrow: 2,
          paddingX: 2,
          height: "56px",
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.grey[600],
        }}
        disableUnderline
      />
      <Divider orientation="vertical" flexItem />
      <Button
        variant="text"
        color="inherit"
        startIcon={<ClipboardIcon />}
        sx={{ paddingX: 2, height: "56px" }}
        onClick={copyToClipboard}
      >
        Copy
      </Button>
    </Box>
  );
};

export default function FormDialog() {
  const [open, setOpen] = useState(false);
  const [generatingLink, setGeneratingLink] = useState(false);
  const [checkoutLink, setCheckoutLink] = useState();
  
  const notify = useNotify();
  const recordId = useGetRecordId();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCheckoutLink(null);
    setOpen(false);
  };

  const { data } = useGetList("tenants", {
    pagination: { page: 1, perPage: 10000 },
  });

  const tenantChoices = data?.map((tenant) => ({
    id: tenant.stripeId,
    name: tenant.name,
  }));

  const generateCheckoutLink = async (values) => {
    setGeneratingLink(true);
    try {
      const functions = getFunctions();
      const createCheckoutSession = httpsCallable(
        functions,
        "createAdminCheckoutSession"
      );

      //stripeId, priceId, quantity, customer_email
      const checkoutUrl = await createCheckoutSession({
        stripeId: values.stripeId,
        priceId: recordId,
        quantity: 1,
      });

      setCheckoutLink(checkoutUrl.data.url)
    } catch (error) {
      notify(error.message, { type: "error" });
    } finally {
      setGeneratingLink(false);
    }
  };

  return (
    <>
      <Button variant="text" onClick={handleClickOpen} startIcon={<AddIcon />}>
        Checkout Link
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Create Checkout Link</DialogTitle>
        <Form onSubmit={generateCheckoutLink}>
          <DialogContent>
            {/* Select Org from dropdown */}
            <AutocompleteInput source="stripeId" choices={tenantChoices} />
            {checkoutLink && <CopyToClipboard checkoutLink={checkoutLink} />}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <LoadingButton type="submit" variant="outlined" loading={generatingLink}>
              Create
            </LoadingButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}
