import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LockIcon from "@mui/icons-material/Lock";
import {
  TextInput,
  Form,
  required,
  useNotify,
  useRefresh,
  useRecordContext,
} from "react-admin";
import { httpsCallable, getFunctions } from "firebase/functions";

const Content = ({tenantId, uid, handleClose}) => {
  const record = useRecordContext();
  const [loading, setLoading] = React.useState(false)
  const notify = useNotify();

  const updatePassword = async (values) => {

    setLoading(true);
    try {
      const functions = getFunctions();
      const updatePassword = httpsCallable(functions, "updatePassword");
      await updatePassword({
        tenantId: record.tenantId,
        uid: record.uid,
        password: values.password,
      });

      notify(`Password Updated!`, { type: "success" });
      handleClose();
    } catch (error) {
      notify(`Error occured when updating user.`, { type: "error" });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={updatePassword}>
      <DialogContent>
        <TextInput
          label="Password"
          source="password"
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <LoadingButton type="submit" variant="outlined" loading={loading}>
          Save
        </LoadingButton>
      </DialogActions>
    </Form>
  );
}

export default function FormDialog({tenantId, uid}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="text" onClick={handleClickOpen} startIcon={<LockIcon />}>
        New Password
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Update Password</DialogTitle>
        <Content tenantId={tenantId} handleClose={handleClose}/>
      </Dialog>
    </>
  );
}
