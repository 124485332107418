import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
} from "react-admin";

export const VendorEdit = () => {
  return (
    <Edit title="Edit Vendor" redirect="list" mutationMode="pessimistic">
      <SimpleForm
        defaultValues={{
          name: "",
          website: "",
          logo: "",
          global: true,
        }}
      >
        <TextInput label="Name" source="name" fullWidth />
        <TextInput
          label="Description"
          source="description"
          multiline
          fullWidth
        />
        <TextInput label="Website" source="website" fullWidth />
        <TextInput label="Logo" source="logo" fullWidth />
        <BooleanInput source="global" disabled />
      </SimpleForm>
    </Edit>
  );
};

export default VendorEdit;
