import * as React from "react";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import {
  TextInput,
  useRecordContext,
  SelectInput,
  Form,
  required,
  useNotify,
  useRefresh,
} from "react-admin";
import { httpsCallable, getFunctions } from "firebase/functions";

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const tenantRecord = useRecordContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createUser = async (values) => {
    setLoading(true);
    try {
      const functions = getFunctions();
      const createUser = httpsCallable(functions, "createUser");
      await createUser({
        tenantId: tenantRecord.id,
        first: values.first,
        last: values.last,
        email: values.email,
        password: values.password,
        phone: values.phone,
        role: values.role,
      });
      
      notify(`User created!`, { type: "success" });
      handleClose();
      refresh();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
      >
        Create
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Create User</DialogTitle>
        <Form onSubmit={createUser}>
          <DialogContent>
            <TextInput
              label="Email"
              source="email"
              type="email"
              fullWidth
              required
            />
            <TextInput
              label="Password"
              source="password"
              type="text"
              fullWidth
              helperText="Password will be auto generated if left blank."
            />

            <TextInput
              label="First Name"
              source="first"
              fullWidth
              validate={[required()]}
            />
            <TextInput
              label="Last Name"
              source="last"
              fullWidth
              validate={[required()]}
            />
            <SelectInput
              source="role"
              label="User Role"
              choices={[
                { id: "field", name: "Field" },
                { id: "admin", name: "Admin" },
                { id: "superadmin", name: "Super Admin" },
              ]}
              validate={[required()]}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <LoadingButton type="submit" loading={loading}>Create</LoadingButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}
