import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  useRecordContext,
  SelectInput
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

const Title = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.name}` : ""}</span>;
};

export const TenantEdit = () => (
  <Edit title={<Title />}>
    <SimpleForm>
      <TextInput label="Id" source="id" disabled fullWidth />
      <TextInput label="Name" source="name" fullWidth />
      <SelectInput
        source="accountType"
        label="Account Type"
        choices={[
          { id: "free", name: "Free" },
          { id: "team", name: "Team" },
          { id: "business", name: "Business" },
          { id: "enterprise", name: "Enterprise" },
        ]}
      />
      {/* <ImageInput label="Logo" source="logoUrl">
        <ImageField title="Logo" source="logoUrl" />
      </ImageInput> */}
      {/* <TextInput label="Roles" source="roles" fullWidth /> */}
      <RichTextInput
        label="Terms & Conditions"
        source="termsAndConditions"
        fullWidth
      />
    </SimpleForm>
  </Edit>
);

export default TenantEdit
