import {
  List,
  Datagrid,
  TextField,
  CreateButton,
  EditButton,
  TextInput,
  FilterForm,
} from "react-admin";
import { Stack } from "@mui/material";

const ListActions = () => {
  const userFilters = [
    <TextInput label="Search by name" source="name" alwaysOn />,
  ];

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ paddingTop: "36px" }}
    >
      <FilterForm filters={userFilters} />
      <CreateButton label="Create" />
    </Stack>
  );
}

export const Tenants = () => (
  <List actions={<ListActions />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="logoUrl" />
      <EditButton />
    </Datagrid>
  </List>
);


export default Tenants;