import {
  Title,
  useList,
  ListContextProvider,
  Datagrid,
  TextField,
  CreateButton,
  EditButton,
  FilterForm,
} from "react-admin";
import ActiveChip from "../../components/ActiveChip";
import { useQuery } from "react-query";
import { Stack, Card } from "@mui/material";
import { httpsCallable, getFunctions } from "firebase/functions";

const ListActions = () => {
  const userFilters = [
    // <TextInput label="Search by name" source="name" alwaysOn />,
  ];

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ paddingTop: "36px" }}
    >
      <FilterForm filters={userFilters} />
      <CreateButton label="Create" />
    </Stack>
  );
};

export const Products = () => {
  const getProducts = httpsCallable(getFunctions(), "products-list");
  const { data, isLoading, error } = useQuery(
    ["products", "products-list", {}],
    () => getProducts({ active: true })
  );

  const listContext = useList({ data: data?.data, isLoading: isLoading, error: error });

  return (
    <ListContextProvider value={listContext}>
      <Title title="Products" />
      <ListActions />
      <Card>
        <Datagrid rowClick="show">
          <TextField source="name" />
          <TextField source="id" />
          <ActiveChip />
          <EditButton />
        </Datagrid>
      </Card>
    </ListContextProvider>
  );
};

export default Products;
