import { Admin, Resource } from "react-admin";
import dataProvider from "./dataProvider";
import authProvider from "./authProvider";
import { TenantList, TenantEdit, TenantShow, TenantCreate } from "./tenants"
import { ProductList, ProductEdit, ProductShow, ProductCreate } from "./products"
import {CorporateFare, Category, Store} from "@mui/icons-material";
import { VendorList, VendorEdit, VendorShow, VendorCreate } from "./vendors";

// Dark banner if PROD, Orange if DEV
const theme = {
  palette: {
    primary: {
      main: "#FF582C",
    },
    secondary: {
      main:
        process.env.REACT_APP_FIREBASE_PROJECT_ID === "tudu-db"
          ? "#233044"
          : "#FF582C",
    },
    error: {
      main: "#f44336",
    },
    warning: {
      main: "#ffad33",
    },
    success: {
      main: "#1bd133",
    },
    info: {
      main: "#2196f3",
    },
    grey: {
      500: "#9e9e9e",
    },
  },
};

const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider} theme={theme}>
    <Resource
      name="tenants"
      icon={CorporateFare}
      list={TenantList}
      edit={TenantEdit}
      show={TenantShow}
      create={TenantCreate}
    />
    <Resource 
      name="vendors" 
      icon={Store} 
      list={VendorList} 
      edit={VendorEdit}
      show={VendorShow}
      create={VendorCreate}
    />
    <Resource
      name="products"
      icon={Category}
      list={ProductList}
      edit={ProductEdit}
      show={ProductShow}
      create={ProductCreate}
    />

    {/* <Resource
      name="tenants/:id/users"
      list={UserList}
      show={UserShow}
      create={UserCreate}
    /> */}
    {/* <Resource name="tenants/[tenant]/users" list={ListGuesser} /> */}
  </Admin>
);

export default App;