import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  TextInput,
  useRecordContext,
  SelectInput,
  Form,
  useNotify,
  useRefresh,
  NumberInput,
  BooleanInput
} from "react-admin";
import EditIcon from "@mui/icons-material/Edit";
import { httpsCallable, getFunctions } from "firebase/functions";

export default function EditDialog() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const priceRecord = useRecordContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const updatePrice = async (values) => {
    setLoading(true);
    try {
      const functions = getFunctions();
      const updatePrice = httpsCallable(functions, "prices-update");
      await updatePrice({
        id: priceRecord.id,
        nickname: values.nickname,
        active: values.active,
      });
      
      notify(`Price updated!`, { type: "success" });
      handleClose();
      refresh();
    } catch (error) {
        notify(error.message, { type: "error" });
    } finally {
        setLoading(false);
    }
  };

  return (
    <>
      <Button variant="text" onClick={handleClickOpen} startIcon={<EditIcon />}>
        Edit
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit Price</DialogTitle>
        <Form
          onSubmit={updatePrice}
          record={{
            ...priceRecord,
            unit_amount: priceRecord.unit_amount / 100,
          }}
        >
          <DialogContent>
            <TextInput
              label="Name"
              source="nickname"
              type="text"
              fullWidth
              required
            />
            <Box
              sx={{ display: "flex", flex: 1, alignItems: "center", gap: 2 }}
            >
              <NumberInput
                label="Unit Amount"
                source="unit_amount"
                type="number"
                margin="dense"
                disabled
              />
              <SelectInput
                source="currency"
                label="Currency"
                choices={[
                  { id: "usd", name: "USD" },
                  { id: "cad", name: "CAD" },
                  { id: "eur", name: "EUR" },
                  { id: "gbp", name: "GBP" },
                ]}
                margin="dense"
                disabled
              />
            </Box>

            <SelectInput
              source="recurring.interval"
              label="Interval"
              choices={[
                { id: "month", name: "Month" },
                { id: "year", name: "Year" },
              ]}
              disabled
            />
            <BooleanInput label="Active" source="active" defaultValue={true} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="outlined"
              loading={loading}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}
