import * as React from "react";
import { Create, SimpleForm, TextInput, BooleanInput, required } from "react-admin";

// export interface Vendor {
//   id: string;
//   name: string;
//   global: boolean;
//   website: string;
//   logo: string;
// }

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Name is required";
  }
  if (!values.website) {
    errors.website = "Website is required";
  }
  if (!values.logo) {
    errors.logo = "Logo is required";
  }
  return errors;
};

export const VendorCreate = () => {
  return (
    <Create title="Create Vendor" redirect="list">
      <SimpleForm
        defaultValues={{
          name: "",
          global: true,
        }}
        validate={validate}
      >
        <TextInput label="Name" source="name" fullWidth />
        <TextInput
          label="Description"
          source="description"
          multiline
          fullWidth
        />
        <TextInput label="Website" source="website" fullWidth />
        <TextInput label="Logo" source="logo" fullWidth />
        <BooleanInput source="global" disabled />
      </SimpleForm>
    </Create>
  );
};

export default VendorCreate;
