import {
  List,
  Datagrid,
  TextField,
  CreateButton,
  EditButton,
  TextInput,
  FilterForm,
  ImageField,
  NumberField,
  BulkDeleteButton
} from "react-admin";
import { Stack } from "@mui/material";

const VendorActions = () => {
  const userFilters = [
    <TextInput label="Search by name" source="name" alwaysOn />,
  ];

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ paddingTop: "36px" }}
    >
      <FilterForm filters={userFilters} />
      <CreateButton label="Create" />
    </Stack>
  );
};

export const Vendors = () => (
  <List actions={<VendorActions />}>
    <Datagrid
      rowClick="show"
      bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
    >
      <ImageField source="logo" />
      <TextField source="name" />
      <TextField source="contactCount" />
      <EditButton />
    </Datagrid>
  </List>
);

export default Vendors;
