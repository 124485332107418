import * as React from "react";
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  RecordContextProvider,
  useGetRecordId,
  ShowButton,
  TopToolbar,
  Title,
  useNotify
} from "react-admin";
import { Card } from "@mui/material";
import { useQuery } from "react-query";
import { httpsCallable, getFunctions } from "firebase/functions";
import { useNavigate } from "react-router-dom";

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Name is required";
  }
  if (!values.description) {
    // You can return translation keys
    errors.active = "Description is required";
  }
  return errors;
};

const EditActions = () => (
  <TopToolbar>
    <ShowButton />
    {/* Add your custom actions */}
    {/* <Button color="primary" onClick={customAction}>Custom Action</Button> */}
  </TopToolbar>
);

export const ProductShow = () => {
  const recordId = useGetRecordId();
  const navigate = useNavigate();
  const notify = useNotify();

  const getProducts = httpsCallable(getFunctions(), "products-get");
  const { data } = useQuery(
    ["products", "products-edit", { id: recordId }],
    () => getProducts({ id: recordId })
  );

  const update = async (values) => {
    try {
      const functions = getFunctions();
      const updateProduct = httpsCallable(functions, "products-update");
      const { data, error } = await updateProduct({
        id: recordId,
        name: values.name,
        active: values.active,
        description: values.description,
      });

      if(error){
        //throw error to break out of try
        throw new Error(error.message);
      }

      navigate(`/products/${data.id}/show`);
      notify("Product Updated", { type: "success" });
    } catch (error) {
      notify(error.message, { type: "error" });
    }
  };

  return (
    <RecordContextProvider value={data?.data} emptyWhileLoading>
      <Title title={data?.name || "Edit Product"} />
      <EditActions />
      <Card>
        <SimpleForm onSubmit={update} validate={validate}>
          <TextInput label="Name" source="name" fullWidth />
          <BooleanInput label="Active" source="active" />
          <TextInput
            label="Description"
            source="description"
            fullWidth
            multiline
            minRows={4}
          />
        </SimpleForm>
      </Card>
    </RecordContextProvider>
  );
};

export default ProductShow;
