import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  TextInput,
  useRecordContext,
  SelectInput,
  Form,
  useInput,
  useNotify,
  useRefresh,
  NumberInput
} from "react-admin";
import { LoadingButton } from "@mui/lab";

import { httpsCallable, getFunctions } from "firebase/functions";
import { styled } from "@mui/material/styles";

const TextField = styled(TextInput)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    paddingLeft: 0,
    paddingRight: 0,
  },
  "& .MuiInputAdornment-positionStart": {
    backgroundColor: theme.palette.grey[50],
    padding: "19px 8px",
    borderRight: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: theme.shape.borderRadius + "px",
    borderBottomLeftRadius: theme.shape.borderRadius + "px",
  },
  "& .MuiInputAdornment-positionEnd": {
    backgroundColor: theme.palette.grey[50],
    padding: "19px 8px",
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
}));

const PriceField = styled(NumberInput)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "4px 0px 0px 4px",
  },
}));

const CurrencyField = styled(SelectInput)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "0px 4px 4px 0px",
  },
}));

const ChartField = styled(TextInput)(({ theme }) => ({
  "& .MuiInputBase-root": {
    border: "0px solid",
  },
  "& MuiOutlinedInput-input": {
    padding: "0px",
  }
}));

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  "& .MuiTableCellBase-root": {
    padding: "0px",
    border: "1px solid #e0e0e0",
  },
}));

export default function FormDialog() {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const productRecord = useRecordContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const createPrice = async (values) => {
    let price = {
      currency: values.currency,
      recurring: values.recurring,
      lookup_key: values.lookup_key,
      product: productRecord.id,
      metadata: values.metadata
    };

    if(values.nickname){
      price.nickname = values.nickname;
    }

    if(values.pricingModel === "standard"){
      price.billing_scheme = "per_unit";
      price.unit_amount = Math.round(values.unit_amount * 100);
    }

    if(values.pricingModel === "package"){
      price.billing_scheme = "per_unit";
      price.unit_amount = Math.round(values.unit_amount * 100);
      price.transform_quantity = {
        divide_by: values.package_qty,
        round: "up"
      }
    }

    if(values.pricingModel === "graduated"){
      delete price.transform_quantity
      price.billing_scheme = "tiered";
      price.tiers_mode = "graduated";
      price.tiers = values.tiers
    }

    try {
      setSubmitting(true);
      const functions = getFunctions();
      const createPrice = httpsCallable(functions, "prices-create");
      await createPrice(price);

      notify(`Price created!`, { type: "success" });
      handleClose();
      refresh();
    } catch (error) {
        notify(error.message, { type: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  const Pricing = () => {
    const { field } = useInput({ source: "pricingModel" });
    const { field: up_to } = useInput({ source: "tiers[0].up_to" });

    if(field.value === "standard"){
      return (
        <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
          <PriceField
            label="Price"
            source="unit_amount"
            type="number"
            margin="dense"
            required
          />
          <CurrencyField
            source="currency"
            choices={[
              { id: "usd", name: "USD" },
              { id: "cad", name: "CAD" },
              { id: "eur", name: "EUR" },
              { id: "gbp", name: "GBP" },
            ]}
            defaultValue="usd"
            margin="dense"
            required
            sx={{ minWidth: "86px" }}
          />
        </Box>
      );
    }

    if (field.value === "package") {
      return (
        <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
          <PriceField
            label="Price"
            source="unit_amount"
            type="number"
            margin="dense"
            required
          />
          <CurrencyField
            source="currency"
            choices={[
              { id: "usd", name: "USD" },
              { id: "cad", name: "CAD" },
              { id: "eur", name: "EUR" },
              { id: "gbp", name: "GBP" },
            ]}
            defaultValue="usd"
            margin="dense"
            required
            sx={{ minWidth: "86px" }}
          />

          <Box
            sx={{
              paddingLeft: 2,
              display: "flex",
              flex: 1,
              alignItems: "center",
              gap: 1,
            }}
          >
            <TextField
              label={false}
              source="package_qty"
              type="number"
              margin="dense"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">per</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">seats</InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      );
    }

    if (field.value === "graduated") {
      return (
        <>
          <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
            <SelectInput
              source="currency"
              choices={[
                { id: "usd", name: "USD" },
                { id: "cad", name: "CAD" },
                { id: "eur", name: "EUR" },
                { id: "gbp", name: "GBP" },
              ]}
              defaultValue="usd"
              margin="dense"
              required
              sx={{ minWidth: "86px" }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              marginBottom: 4,
              border: "1px solid #e0e0e0",
            }}
          >
            <TableContainer>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="left">First Seat</TableCell>
                    <TableCell align="left">Last Seat</TableCell>
                    <TableCell align="left">Per Seat</TableCell>
                    <TableCell align="left">Flat Fee</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key="first"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" sx={{ width: 100 }}>
                      FOR THE FIRST
                    </TableCell>
                    <TableCell align="left">0</TableCell>
                    <TableCell align="left">
                      <ChartField
                        label={false}
                        source="tiers[0].up_to"
                        type="number"
                        margin="dense"
                        helperText={false}
                        defaultValue={1}
                        required
                      />
                    </TableCell>
                    <TableCell align="left">
                      <ChartField
                        label={false}
                        source="tiers[0].unit_amount"
                        type="number"
                        margin="dense"
                        placeholder="0.00"
                        parse={(value) => Number(value * 100)}
                        format={(value) => value / 100 || null}
                        helperText={false}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        required
                      />
                    </TableCell>
                    <TableCell align="left">
                      <ChartField
                        label={false}
                        source="tiers[0].flat_amount"
                        type="number"
                        margin="dense"
                        placeholder="0.00"
                        parse={(value) => Number(value * 100)}
                        format={(value) => value / 100 || null}
                        helperText={false}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        required
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="second"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      FOR THE NEXT
                    </TableCell>
                    <TableCell align="left">
                      {parseInt(up_to.value) + 1}
                    </TableCell>
                    <TableCell align="left">
                      <ChartField
                        label={false}
                        source="tiers[1].up_to"
                        type="text"
                        margin="dense"
                        helperText={false}
                        defaultValue="inf"
                        format={(value) => "∞"}
                        disabled
                        required
                      />
                    </TableCell>
                    <TableCell align="left">
                      <ChartField
                        label={false}
                        source="tiers[1].unit_amount"
                        type="number"
                        margin="dense"
                        placeholder="0.00"
                        parse={(value) => Number(value * 100)}
                        format={(value) => value / 100 || null}
                        helperText={false}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        required
                      />
                    </TableCell>
                    <TableCell align="left">
                      <ChartField
                        label={false}
                        source="tiers[1].flat_amount"
                        type="number"
                        margin="dense"
                        placeholder="0.00"
                        parse={(value) => Number(value * 100)}
                        format={(value) => value / 100 || null}
                        helperText={false}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        required
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
      >
        Create
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Create Price</DialogTitle>
        <Form onSubmit={createPrice}>
          <DialogContent>
            <TextInput label="Name" source="nickname" type="text" fullWidth />
            <SelectInput
              source="metadata.type"
              label="Type"
              choices={[{ id: "enterprise", name: "Enterprise" }]}
              defaultValue="standard"
              required
            />

            <h4>Pricing Model</h4>
            <SelectInput
              source="pricingModel"
              label="Pricing Model"
              choices={[
                { id: "standard", name: "Standard Pricing" },
                {
                  id: "graduated",
                  name: "Enterprise Pricing",
                  disabled: false,
                },
                { id: "package", name: "Package Pricing", disabled: true },
              ]}
              defaultValue="standard"
              required
            />

            <h4>Pricing Details</h4>
            <Pricing />
            <SelectInput
              source="recurring.interval"
              label="Interval"
              choices={[
                { id: "month", name: "Month" },
                { id: "year", name: "Year" },
              ]}
              defaultValue="year"
              required
            />

            {/* <TextInput
              label="Lookup Key"
              source="lookup_key"
              type="text"
              fullWidth
              helperText="This is how we will identify this price in the app."
              required
            /> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="outlined"
              loading={submitting}
            >
              Create
            </LoadingButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
}
