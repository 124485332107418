import * as React from "react";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import {
  TextInput,
  useRecordContext,
  Form,
  required,
  useNotify,
  useRefresh,
  useCreate,
  CreateBase,
} from "react-admin";

// export interface VendorContact {
//   id?: string;
//   name: string;
//   email: string;
//   phone?: string;
//   global: boolean;
//   vendor: Vendor;
//   createdAt?: Timestamp;
//   inMyContacts?: boolean;
// }

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const vendor = useRecordContext();
  const [create] = useCreate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createUser = async (values) => {
    const cleanedValues = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      global: true,
      vendor: {
        id: vendor.id,
        name: vendor.name,
        global: vendor.global,
        website: vendor.website || null,
        logo: vendor.logo || null,
      }
    }
    console.log(cleanedValues);
    setLoading(true);
    try {
      await create("vendorContacts", {
        data: cleanedValues
      });
      
      notify(`Contact created!`, { type: "success" });
      handleClose();
      refresh();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CreateBase>
      <Button variant="text" onClick={handleClickOpen} startIcon={<AddIcon />}>
        Create
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Create User</DialogTitle>
        <Form defaultValues={{name: "", email: "", phone: ""}} onSubmit={createUser}>
          <DialogContent>
            <TextInput
              label="Name"
              source="name"
              fullWidth
              validate={[required()]}
            />
            <TextInput
              label="Email"
              source="email"
              type="email"
              fullWidth
              validate={[required()]}
            />
            <TextInput label="Phone" source="phone" type="text" fullWidth />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <LoadingButton type="submit" loading={loading}>
              Create
            </LoadingButton>
          </DialogActions>
        </Form>
      </Dialog>
    </CreateBase>
  );
}
