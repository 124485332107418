import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  useNotify
} from "react-admin";
import { httpsCallable, getFunctions } from "firebase/functions";
import { useNavigate } from "react-router-dom";



export const TenantCreate = () => {
  const navigate = useNavigate();
  const notify = useNotify();

  const createTenant = async (values) => {
    try {
      const functions = getFunctions();
      const createTenant = httpsCallable(functions, "createTenant");
      const { data } = await createTenant({
        displayName: values.displayName,
        name: values.name,
        domain: values.domain,
        accountType: values.accountType,
      });

      navigate(`/tenants/${data.tenantId}/show`);
      notify("Tenant Created", { type: "success" }); 
    } catch (error) {
      notify(error.message, {type: "error"}); 
      console.log(error);
    }
  };

  return(
    <Create title="Create Tenant" redirect="list">
      <SimpleForm onSubmit={createTenant}>
        {/* For GCloud Identity */}
        <TextInput label="Name" source="name" fullWidth />
        <TextInput label="Display Name (letters, digits, hyphens)" source="displayName" fullWidth />
        {/* <ImageInput label="Logo" source="logoUrl">
          <ImageField title="Logo" source="logoUrl" />
        </ImageInput> */}
        {/* <TextInput label="Roles" source="roles" fullWidth /> */}
      </SimpleForm>
    </Create>
  )
};

export default TenantCreate
