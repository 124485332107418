import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  useNotify,
} from "react-admin";
import { httpsCallable, getFunctions } from "firebase/functions";
import { useNavigate } from "react-router-dom";

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Name is required";
  }
  if (!values.description) {
    // You can return translation keys
    errors.active = "Description is required";
  }
  return errors;
};

export const ProductCreate = () => {
  const navigate = useNavigate();
  const notify = useNotify();

  const create = async (values) => {
    try {
      const functions = getFunctions();
      const createProduct = httpsCallable(functions, "products-create");
      await createProduct({
        name: values.name,
        active: values.active,
        description: values.description,
      });

      navigate(`/products`);
      notify("Product Created", { type: "success" }); 
    } catch (error) {
      notify(error.message, {type: "error"}); 
    }
  };

  return (
    <Create title="Create Product" redirect="list">
      <SimpleForm onSubmit={create} validate={validate}>
        <TextInput label="Name" source="name" fullWidth />
        {/* <SelectInput
          source="metadata.type"
          label="Product Type"
          choices={[{ id: "enterprise", name: "Enterprise" }]}
          defaultValue="standard"
          required
        /> */}
        <BooleanInput label="Active" source="active" defaultValue={true} />
        <TextInput
          label="Description"
          source="description"
          fullWidth
          multiline
          minRows={4}
          required
        />
      </SimpleForm>
    </Create>
  );
};

export default ProductCreate
