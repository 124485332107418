import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import {
  TextInput,
  SelectInput,
  Form,
  required,
  useNotify,
  useRefresh,
} from "react-admin";
import { doc, getFirestore, updateDoc } from "firebase/firestore"; 

const Content = ({tenantId, handleClose}) => {
  const [loading, setLoading] = React.useState(false)
  const notify = useNotify();
  const refresh = useRefresh();

  const updateUser = async (values) => {
    setLoading(true);
    try {
      const db = getFirestore();
      const userRef = doc(db, "tenants", tenantId, "users", values.id);
      await updateDoc(userRef, {
        tenantId: tenantId,
        first: values.first,
        last: values.last,
        email: values.email,
        phone: values.phone,
        role: values.role,
      });

      notify(`User updated!`, { type: "success" });
      handleClose();
      refresh();
    } catch (error) {
      notify(`Error occured when updating user.`, { type: "error" });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={updateUser}>
      <DialogContent>
        <TextInput
          label="First Name"
          source="first"
          fullWidth
          validate={[required()]}
        />
        <TextInput
          label="Last Name"
          source="last"
          fullWidth
          validate={[required()]}
        />
        <TextInput
          label="Email"
          source="email"
          type="email"
          fullWidth
          required
        />
        <TextInput
          label="Phone"
          source="phone"
          fullWidth
          validate={[required()]}
        />
        <SelectInput
          source="role"
          label="User Role"
          choices={[
            { id: "field", name: "Field" },
            { id: "admin", name: "Admin" },
            { id: "superadmin", name: "Super Admin" },
          ]}
          validate={[required()]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <LoadingButton type="submit" variant="outlined" loading={loading}>
          Save
        </LoadingButton>
      </DialogActions>
    </Form>
  );
}

export default function FormDialog({tenantId}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="text" onClick={handleClickOpen} startIcon={<EditIcon />}>
        Edit
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit User</DialogTitle>
        <Content tenantId={tenantId} handleClose={handleClose}/>
      </Dialog>
    </>
  );
}
