import * as React from "react";
import {
  SimpleShowLayout,
  ImageField,
  TextField,
  SelectField,
  RecordContextProvider,
  useGetRecordId,
  EditButton,
  TopToolbar,
  Title,
  ListContextProvider,
  useList,
  Datagrid,
  FunctionField,
  Loading,
} from "react-admin";
import { Card, Stack } from "@mui/material";
import ActiveChip from "../../components/ActiveChip";
import PriceCreateDialog from "../prices/PriceCreateDialog";
import PriceEditDialog from "../prices/PriceEditDialog";
import CreateCheckoutDialog from "../../components/CreateCheckoutDialog";

import { useQuery } from "react-query";
import { httpsCallable, getFunctions } from "firebase/functions";

const ShowActions = () => (
    <TopToolbar>
        <EditButton />
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={customAction}>Custom Action</Button> */}
    </TopToolbar>
);

const Toolbar = () => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ paddingTop: "36px" }}
    >
      <h2>Plans</h2>
      <PriceCreateDialog />
    </Stack>
  );
};

const PriceList = () => {
  const recordId = useGetRecordId();

  const getPrices = httpsCallable(getFunctions(), "prices-list");
  const { data, isLoading, error } = useQuery(
    ["prices", "prices-list", { product: recordId }],
    () => getPrices({ product: recordId, expand: ["data.tiers"] })
  );

  const listContext = useList({
    data: data?.data,
    isLoading: isLoading,
    error: error,
  });

  const pricePerUnit = (record) => {
    let price

    // tiered billing
    if (record.billing_scheme === "tiered" && record.tiers.length > 0) {
      price = `Starts at 
        ${new Intl.NumberFormat("us-EN", {
          style: "currency",
          currency: "usd",
        }).format(record.tiers[0].unit_amount / 100)} per seat + 
        ${new Intl.NumberFormat("us-EN", {
          style: "currency",
          currency: "usd",
        }).format(record.tiers[0].unit_amount / 100)} / ${record.recurring.interval}`;
    }

    // package billing
    if(record.billing_scheme === "per_unit" && record.transform_quantity?.divide_by > 1) {
      price = `${new Intl.NumberFormat("us-EN", {
        style: "currency",
        currency: "usd",
      }).format(record.unit_amount / 100)} per group of ${
        record.transform_quantity?.divide_by
      }`
    }

    // standard billing
    if(record.billing_scheme === "per_unit" && !record.transform_quantity) {
      price = `${new Intl.NumberFormat("us-EN", {
        style: "currency",
        currency: "usd",
      }).format(record.unit_amount / 100)} per seat`
    }

    return(price)
  }

  return (
    <ListContextProvider value={listContext}>
      <Toolbar />
      <Card>
        <Datagrid bulkActionButtons={false} rowClick={false}>
          <TextField label="Name" source="nickname" />
          {/* <TextField label="Id" source="id" /> */}
          <ActiveChip source="active" />
          <SelectField
            label="Recurring"
            source="recurring.interval"
            choices={[
              { id: "day", name: "Daily" },
              { id: "week", name: "Weekly" },
              { id: "month", name: "Monthly" },
              { id: "year", name: "Yearly" },
            ]}
          />
          <SelectField
            label="Scheme"
            source="billing_scheme"
            choices={[
              { id: "per_unit", name: "Per Unit" },
              { id: "tiered", name: "Tiered" },
            ]}
          />
          <FunctionField
            label="Price/Seat"
            render={pricePerUnit}
          />
          <PriceEditDialog />
          <CreateCheckoutDialog />
        </Datagrid>
      </Card>
    </ListContextProvider>
  );
}


export const ProductShow = () => {
  const recordId = useGetRecordId();

  const getProducts = httpsCallable(getFunctions(), "products-get");
  const { data, isLoading } = useQuery(
    ["products", "products-get", {id: recordId}],
    () => getProducts({id: recordId})
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <RecordContextProvider value={data?.data} emptyWhileLoading>
      <Title title={data?.name || "Products"} />
      <ShowActions />
      <Card>
        <SimpleShowLayout>
          <ImageField source="logoUrl" label={false} />
          <TextField source="name" />
          <TextField source="id" />
          <TextField source="description" emptyText="-" />
          <ActiveChip source="active" />
          <TextField source="metadata.type" label="Plan Type" emptyText="-" />
        </SimpleShowLayout>
      </Card>
      <PriceList />
    </RecordContextProvider>
  );
};

export default ProductShow
