import * as React from "react";
import {
  ShowBase,
  Show,
  SimpleShowLayout,
  ImageField,
  TextField,
  SelectField,
  useRecordContext,
  Datagrid,
  ListBase,
  TextInput,
  FilterForm,
  FunctionField
} from "react-admin";
import { Stack, Link } from "@mui/material";
import UserCreateDialog from "../users/UserCreateDialog";
import UserEditDialog from "../users/UserEditDialog";
import UpdatePassword from "../users/UpdatePasswordDialog";
import { Chip } from "@mui/material";

const userFilters = [
  <TextInput label="Search by email" source="email" alwaysOn />,
];

const Title = () => {
  const record = useRecordContext();
  return <span>{record ? `${record.name}` : ""}</span>;
};

const ActiveChip = () => {
  const record = useRecordContext();
  return (
    <Chip
      label={record.status === "active" ? "Active" : "Inactive"}
      color={record.status === "active" ? "success" : "error"}
      variant="outlined"
    />
  );
};

const Toolbar = () => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ marginTop:"36px"}}
      gap={4}
    >
      <h3>Users</h3>
      <FilterForm filters={userFilters} />
      <UserCreateDialog />
    </Stack>
  );
};

const UserList = () => {
  const record = useRecordContext();
  const CustomEmpty = () => <div>No Users Found.</div>;

  return (
    <ListBase resource={`tenants/${record?.id}/users`}>
      <Toolbar />
      <Datagrid empty={<CustomEmpty />} bulkActionButtons={<></>}>
        <TextField label="Id" source="id" />
        <TextField label="First" source="first" />
        <TextField label="Last" source="last" />
        <TextField label="Email" source="email" />
        <TextField label="Phone" source="phone" />
        <TextField label="Role" source="role" />
        <UpdatePassword />
        <UserEditDialog tenantId={record?.id} />
      </Datagrid>
    </ListBase>
  );
};

const SubscriptionsList = () => {
  const record = useRecordContext();

  const CustomEmpty = () => (
    <div>
      <p>No Subscriptions Found.</p>
    </div>
  );

  const CustomToolbar = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginTop: "36px" }}
        gap={4}
      >
        <h3>Subscriptions</h3>
      </Stack>
    );
  };

  return (
    <ListBase resource={`tenants/${record?.id}/subscriptions`}>
      <CustomToolbar />
      <Datagrid empty={<CustomEmpty />} bulkActionButtons={<></>}>
        <TextField label="Plan" source="plan.nickname" />
        <FunctionField
          label="Plan"
          source="plan.id"
          render={(record) => (
            <Link href={`/#/products/${record.plan.product}/show?price=${record.plan.id}`}>
              {record.nickname || record.plan.id}
            </Link>
          )}
        />
        <Link label="Id" source="id" />
        <ActiveChip source="Status" />
        <TextField label="Name" source="name" />
        <UserEditDialog tenantId={record?.id} />
      </Datagrid>
    </ListBase>
  );
};


export const TenantShow = () => {
  return (
    <ShowBase>
      <Show title={<Title />}>
        <SimpleShowLayout>
          <ImageField source="logoUrl" label={false} />
          <TextField source="name" />
          <TextField source="id" />
          <SelectField
            source="accountType"
            choices={[
              { id: "free", name: "Free" },
              { id: "team", name: "Team" },
              { id: "business", name: "Business" },
              { id: "enterprise", name: "Enterprise" },
            ]}
          />

          <TextField source="termsAndConditions" />
        </SimpleShowLayout>
      </Show>
      <UserList />
      <SubscriptionsList />
    </ShowBase>
  );
};

export default TenantShow
