import { useRecordContext } from 'react-admin'
import { Chip } from '@mui/material'

const ActiveChip = () => {
  const record = useRecordContext()
  return (
    <Chip
      label={record.active ? 'Active' : 'Inactive'}
      color={record.active ? 'success' : 'error'}
      variant="outlined"
    />
  )
}

export default ActiveChip