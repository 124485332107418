import * as React from "react";
import { Stack } from "@mui/material";
import {
  ShowBase,
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  ImageField,
  UrlField,
  useRecordContext,
  Datagrid,
  useGetList,
  useList,
  ListContextProvider
} from "react-admin";
import ContactCreateDialog from "../vendorContacts/ContactCreateDialog";
import ContactEditDialog from "../vendorContacts/ContactEditDialog";

// export interface Vendor {
//   id: string;
//   name: string;
//   global: boolean;
//   website: string;
//   logo: string;
// }

const VendorContactList = () => {
  const record = useRecordContext();

  const { data, isLoading, error } = useGetList("vendorContacts", { filter: { "vendor.id": record?.id }});
  const listContext = useList({
    data: data,
    isLoading: isLoading,
    error: error,
  });

  const CustomEmpty = () => (
    <div>
      <p>No Contacts Found.</p>
    </div>
  );

  const CustomToolbar = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginTop: "36px" }}
        gap={4}
      >
        <h3>Vendor Contacts</h3>
        <ContactCreateDialog />
      </Stack>
    );
  };

  return (
    <ListContextProvider value={listContext}>
      <CustomToolbar />
      <Datagrid empty={<CustomEmpty />} bulkActionButtons={false}>
        <TextField label="Name" source="name" />
        <TextField label="Email" source="email" />
        <TextField label="Phone" source="phone" />
        <TextField label="ID" source="id" />
        <ContactEditDialog />
      </Datagrid>
    </ListContextProvider>
  );
};

export const VendorShow = () => {
  return (
    <ShowBase>
      <Show title="Show Vendor">
        <SimpleShowLayout>
          <TextField label="Name" source="name" />
          <TextField label="description" source="description" />
          <UrlField source="website" />
          <ImageField source="logo" />
          <BooleanField source="global" />
          <TextField label="ID" source="id" />
        </SimpleShowLayout>
      </Show>

      <VendorContactList />
    </ShowBase>
  );
};

export default VendorShow;
